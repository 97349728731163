import React from 'react'
import { NavbarLanding } from '../landingPage/NavbarLanding'
import { Box, Card, CardMedia, Container, Typography } from '@mui/material'
import { Paragraphe, SectionStartTypography } from '../Components'
import { FooterComponent } from '../landingPage/FooterComponent'

export const CulturePage = ({session}) => {
  return (
    <>
        <NavbarLanding />
        <Container>
            <Card
                sx={{
                    bgcolor: '#F6FAF7',
                    mt: 1
                }}
            >
                <CardMedia
                    sx={{ height: 400 }}
                    image="https://res.cloudinary.com/mouss/image/upload/v1689420552/safina/img-2369_oupd5f.jpg"
                    title="Salle image"
                />
            </Card>
            <Box>
                <Typography variant='h6' my={2} fontWeight={'bold'}>Activités culturelles et structure de la maison safina</Typography>
                <Paragraphe><Typography variant='body1' component={'span'} fontWeight={'bold'}>LA DIRECTION</Typography> : La direction est constituée du père responsable de la maison, de son vicaire et des laïcs qui participent à la bonne gestion dans un esprit synodale.</Paragraphe>
                <Paragraphe><Typography variant='body1' component={'span'} fontWeight={'bold'}>LE NOYAU</Typography> : Pour la mission pastorale de la maison safina, le noyau reste la pièce maitresse dans la coordination des activites culturelles et chretiennes. Il est essentiellement constitué de jeunes membres représentés par un président, un vice-président, un secretaire, les consultants, les coordonateurs et vice-coordonateurs des cellules. La reunion du noyau se tient chaque deuxième samedi du mois au cours de laquelle au moins un membre de la direction participe. C'est au cours de celle-ci que les activités tenues et non tenues du  mois précedent sont évaluées. Le noyau a également le devoir de  sensurer les activités proposées à la reunion mensuelle de chaque cellule.</Paragraphe>
                <Paragraphe><Typography variant='body1' component={'span'} fontWeight={'bold'}>LA CELLULE</Typography> : La cellule est dirigée par un coordonateur et un vice-coordonateur élus par les  membres de la cellule. La reunion mensuelle se tient chaque premier dimanche du mois et l'adhésion est libre et gratuite. Au cours de cette reunion, les activités sont proposées selon la thématique de la cellule. Il existe 10 cellules et chacune aborde une thématique particulière.</Paragraphe>
                <Paragraphe component={'ul'}>
                    <Typography component={'li'}>
                        <Typography variant='body1' component={'span'} fontWeight={'bold'}>Cellule conférence</Typography> : les membres discutent et proposent des sujet de conférence et tout cela en se basant sur les problèmes de la société, les fait réels pour soit eveiller la conscience des jeunes, soit pour l'apprentissage.
                    </Typography>
                    <Typography component={'li'}>
                        <Typography variant='body1' component={'span'} fontWeight={'bold'}>Cellule nature et écologie</Typography> : elle s'occupe de tout ce qui est de la nature et l'écologie pour une vie équilibrée entre l'homme et son environnement.
                    </Typography>
                    <Typography component={'li'}>
                        <Typography variant='body1' component={'span'} fontWeight={'bold'}>Cellule vie et animation chretienne</Typography> :  elle s'occupe de la formation chretienne, à travers les récollections, retraites, concerts et bien d'autres exercices de piété.
                    </Typography>
                    <Typography component={'li'}>
                        <Typography variant='body1' component={'span'} fontWeight={'bold'}>Cellule spectacle</Typography> : la valorisation de la culture avec la musique et théâtre.
                    </Typography>
                    <Typography component={'li'}>
                        <Typography variant='body1' component={'span'} fontWeight={'bold'}>Cellules accueille, santé, sport et loisirs, developpement, lecture,rendez-vous des élèves et la cellule Film.</Typography>
                    </Typography>
                </Paragraphe>
                <Paragraphe>La maison safina accuelle tous les jeunes sans tenir compte de confession religieuse.</Paragraphe>
            </Box>
        </Container>
        <FooterComponent session={session} />
    </>
  )
}
