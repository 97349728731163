import { CatchingPokemon, Facebook, Google, LinkedIn, Person, Twitter, YouTube } from '@mui/icons-material'
import { Box, Container, Grid, Icon, IconButton, Link, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import supabase from '../../config/SupabaseClient'
import { NavLink } from 'react-router-dom'

export const FooterComponent = ({session}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signin = () => {
        supabase.auth.signInWithOAuth({provider: 'google'});
    }

    const logOut = () => {
        supabase.auth.signOut();
        handleClose();
    }
  return (
    <Box
        id='contact'
        py={3}
    >
        <Container>
            <Grid container>
                <Grid item xs={6} md={3}>
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    >
                        <List
                            subheader={
                                <ListSubheader component={'div'}>
                                    Liens importants
                                </ListSubheader>
                            }
                        >
                        <ListItem>
                                <Link href='#' underline='none'>
                                    <ListItemText primary="Accueil" />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href='#about' underline='none'>
                                    <ListItemText primary="À propos" />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href='#services' underline='none'>
                                    <ListItemText primary="Services" />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href='#partenaires' underline='none'>
                                    <ListItemText primary="Partenaires" />
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                </Grid>

                <Grid item xs={6} md={3}>
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                >
                        <List
                            subheader={
                                <ListSubheader component={'div'}>
                                    Contacts
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <Link href='mailto:maisonsafina1998@gmail.com' underline='none'>
                                    <ListItemText  primary="contactez-nous" />
                                </Link>
                            </ListItem>
                            {/* <ListItem>
                                <ListItemText primary="+243 904 368 674" />
                            </ListItem> */}
                            <ListItem>
                                <ListItemText primary="Likasi coin kamanyola N°122" />
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
                <Grid item xs md={6}>
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    >
                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                        >
                            <Icon sx={{
                                fontSize: 100
                            }}>
                                <img src='/logo.png' width={100} />
                            </Icon>
                            <Typography variant='body2' textAlign='center'>Former de jeunes personnes à une culture ouverte, riche et créative, ainsi qu'à une spiritualité chrétienne authentique.</Typography>
                        </Box>
                        <Box>
                            {/* <IconButton sx={{
                                '&:hover' : {
                                    color: 'info.dark'
                                }
                            }}>
                                <LinkedIn />
                            </IconButton>

                            <IconButton sx={{
                                '&:hover' : {
                                    color: 'info.dark'
                                }
                            }}>
                                <Twitter />
                            </IconButton> */}

                            <IconButton 
                                sx={{
                                '&:hover' : {
                                    color: 'error.light'
                                    }
                                }}
                                href="https://www.youtube.com/@MaisonSafina-yr3by"
                            >
                                <YouTube />
                            </IconButton>

                            <IconButton 
                                sx={{
                                '&:hover' : {
                                    color: 'info.dark'
                                    }
                                }}
                                href="https://web.facebook.com/profile.php?id=61551753999919"
                            >
                                <Facebook />
                            </IconButton>

                            {
                                session ? (
                                    <>
                                        <IconButton
                                            id="person-button"
                                            sx={{
                                                '&:hover' : {
                                                    color: 'black'
                                                }
                                            }}
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        >
                                            <Person />
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                            'aria-labelledby': 'person-button',
                                            }}
                                        >
                                            <NavLink
                                                to='/dashboard'
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'inherit'
                                                }}
                                            >
                                                <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                                            </NavLink>
                                            <MenuItem onClick={logOut}>Logout</MenuItem>
                                        </Menu>
                                    </>
                                ): (
                                    <IconButton sx={{
                                        '&:hover' : {
                                            color: 'black'
                                        }
                                    }}
                                    onClick={signin}
                                    >
                                        <Person />
                                    </IconButton>
                                )
                            }

                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
  )
}
