import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CampaignIcon from '@mui/icons-material/Campaign';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CancelIcon from '@mui/icons-material/Cancel';

import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Modal, Tooltip, Typography } from '@mui/material';
import supabase from '../../../config/SupabaseClient';
import { permissionEmail } from '../../Components';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
);
  
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export const AnnoncePage = ({open}) => {
    const [annonces, setAnnonces] = useState([]);
    const [openUpdateModal, setOpenUpdateModal] = useState(null);

    const [email, setEmail] = useState(null);
    
    const getAnnonces = async () => {
        let { data, error } = await supabase
            .from('Annonce')
            .select('*')

        if (data) {
            setAnnonces(data)
        }

        if (error) {
            console.log(error.message)
        }
    }

    const handleDelete = async(id) => {
        const { error } = await supabase
        .from('Annonce')
        .delete()
        .eq('id', id)
        
        if (error) {
            alert(error.message);
            setOpenUpdateModal(null)
        } else {
            setOpenUpdateModal(null)
        }
    }

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setEmail(session.user.email)
            })
        getAnnonces();
    }, [openUpdateModal, annonces]);

    return (
    <Main open={open}>
        <DrawerHeader />
        <Typography variant="h6">Annonces</Typography>
        <Grid container rowSpacing={2} columnSpacing={1}>
          {
            annonces.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardMedia
                            component={'img'}
                            image={item.image}
                            height={100}
                        />
                        <CardContent
                        >
                            <CampaignIcon fontSize='large' color='primary' />
                            <Typography variant="body1" fontWeight={'bold'}>{item.titre}</Typography>
                        </CardContent>
                        {
                            permissionEmail.includes(email) && (
                            <CardActions>
                                <ButtonGroup
                                orientation="horizontal"
                                variant="outlined"
                                size="small"
                                fullWidth
                                >
                                    <Tooltip title="Supprimer">
                                        <Button color="error" onClick={() => setOpenUpdateModal(item.id)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Tooltip>
                                </ButtonGroup>
                            </CardActions>
                            )
                        }
                    </Card>
                    {
                        openUpdateModal === item.id && (
                            <Modal
                            open={openUpdateModal}
                            onClose={() => setOpenUpdateModal(null)}
                            >
                                <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    bgcolor: 'background.transparent',
                                    p: 4
                                }}
                                width= {{
                                    xs: '85%',
                                    md: '65%'
                                }}
                                >
                                    <Card>
                                        <CardHeader
                                            avatar={<PriorityHighIcon color='error' />}
                                            title={<Typography variant='h6' fontWeight={'bold'}>Supprimer l'annonce !</Typography>}
                                        />
                                        <CardContent>
                                            <Typography>Voulez-vous suppimer l'annonce <Typography fontWeight={'bold'} component={'span'}>{item.titre}</Typography> ?</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <ButtonGroup
                                            orientation="horizontal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            >
                                                <Tooltip title="Annuler">
                                                    <Button onClick={() => setOpenUpdateModal(null)}>
                                                        <CancelIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Supprimer">
                                                    <Button color="error" onClick={() => handleDelete(item.id)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Modal>
                        )
                    }
                </Grid>
            ))
          }

        </Grid>
    </Main>
  )
}
