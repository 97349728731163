import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AvisComponent } from './AvisComponent'
import { BannerComponent } from './BannerComponent'
import { FooterComponent } from './FooterComponent'
import { NavbarLanding } from './NavbarLanding'
import { Partner } from './Partner'
import { SallesSlider } from './SallesSlider'
import { ServiceComponent } from './ServiceComponent'
import { TeamsComponent } from './TeamsComponent'
import { AnnonceComponent } from './AnnonceComponent'
import supabase from '../../config/SupabaseClient'

export const LandingPage = ({session}) => {
  const [annonces, setAnnonces] = useState([]);

  const getAnnonces = async () => {
    let { data, error } = await supabase
    .from('Annonce')
    .select('*')
    .gte('date_end', new Date(new Date().setHours(0,0,0,0)).toUTCString())

    if (data) {
      console.log(data)
      setAnnonces(data)
    }

    if (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAnnonces();
  }, []);

  return (
    <Box>
        <NavbarLanding />
        <BannerComponent />
        {
          annonces.length !== 0 && (
            <AnnonceComponent annonces={annonces} />
            )
        }
        <Partner />
        <ServiceComponent />
        <TeamsComponent />
        <SallesSlider />
        <AvisComponent />
        <FooterComponent session={session} />
    </Box>
  )
}
