import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import CancelIcon from '@mui/icons-material/Cancel';
import supabase from '../../../config/SupabaseClient';


export default function TabsHomePage() {
    const [value, setValue] = React.useState('1');
    const [commands, setCommands] = React.useState([]);
    const [commandsEncours, setCommandsEncours] = React.useState([]);
    const [commandsEnRetard, setCommandsEnRetard] = React.useState([]);
    const [changes, setChanges] = React.useState(false);

    const getCommands = async () => {
        const { data, error } = await supabase
        .from('Commande')
        .select('id, notified, Reservation!inner (id, description, client_name, client_first_name, date_begin, date_end, Salle!inner (id, name, alias, reserved))')
        .eq('Reservation.Salle.reserved', 1)
        .eq('notified', 0)
        if (error) {
            console.log(error)
            return;
        }

        
        if (data.length) {
            console.log("rient du tout !")
            data.filter((column) => {
                column.is_out = (new Date() > new Date(column.Reservation.date_end))
            })
            
            setCommands(data);
            
        }
    }

    const getCommandsEnCours = async () => {
        const { data, error } = await supabase
        .from('Commande')
        .select('id, notified, Reservation!inner (id, description, client_name, client_first_name, date_begin, date_end, Salle!inner (id, name, alias, reserved))')
        .gte('Reservation.date_end', new Date().toUTCString())
        .eq('Reservation.Salle.reserved', 1)
        .eq('notified', 0)

        if (error) {
            console.log(error)
            return;
        }
        if (data.length) {
            data.filter((column) => {
                column.is_out = (new Date() > new Date(column.Reservation.date_end))
            })
            
            console.log(data)
            
            setCommandsEncours(data);
        }

    }

    const getCommandsEnRetard = async () => {
        const { data, error } = await supabase
        .from('Commande')
        .select('id, notified, Reservation!inner (id, description, client_name, client_first_name, date_begin, date_end, Salle!inner (id, name, alias, reserved))')
        .lte('Reservation.date_end', new Date().toUTCString())
        .eq('Reservation.Salle.reserved', 1)
        .eq('notified', 0)

        if (error) {
            console.log(error)
            return;
        }

        if (data.length) {

            data.filter((column) => {
                column.is_out = (new Date() > new Date(column.Reservation.date_end))
            })
            
            console.log(data)
            
            setCommandsEnRetard(data);
        }

    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    React.useEffect(() => {
        getCommands();
        getCommandsEnCours();
        getCommandsEnRetard();
        
    }, [changes]);


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Réservations" value="1" />
            <Tab label="En cours" value="2" />
            <Tab label="En retard" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <ListTabs list={commands} setChanges={setChanges} />
        </TabPanel>
        <TabPanel value="2">
            <ListTabs list={commandsEncours} setChanges={setChanges} />
        </TabPanel>
        <TabPanel value="3">
            <ListTabs list={commandsEnRetard} setChanges={setChanges} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

const ListTabs = ({list, setChanges}) => {
    
    return (
        <>
            <List>
                {
                    list.map((item, index) => (
                        <CardList key={item.id} item={item} setChanges={setChanges} />
                    ))
                }
            </List>
            {
                (list.length === 0) ? (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <img width={'60%'} src='https://res.cloudinary.com/mouss/image/upload/v1695461050/safina/chojxr8ddyzxpe3p0znu.png' />
                    </Box>
                ): ("")
            }
        </>
    )
}

const CardList = ({item, setChanges}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setChanges((prevState) => !prevState);
        setOpen(false);
    };
    return (
        <>
            <ListItem
                secondaryAction={
                    <Tooltip title='Annuler'>
                        <IconButton onClick={handleClickOpen}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                }
                disablePadding
            >
                <ListItemButton
                    sx={{
                        borderRadius: 1
                    }}
                >
                    <ListItemIcon>
                        <EventIcon color={item.is_out ? 'error': 'primary'} />
                    </ListItemIcon>
                    {
                        console.log(item)
                    }
                    <ListItemText primary={item.Reservation.description} secondary={`${item.Reservation.Salle.name} - ${item.Reservation.client_first_name} ${item.Reservation.client_name}`} />
                </ListItemButton>
            </ListItem>
            <DialogComponent open={open} handleClose={handleClose} data={{is_out : item.is_out, salle: {name: item.Reservation.Salle.name, id: item.Reservation.Salle.id}, reservation_id: item.Reservation.id, command_id: item.id}} />
        </>
    )
}

const DialogComponent = ({open, handleClose, data}) => {

    const librerSalle = async() => {
        const { dataLiberation, error } = await supabase
            .from('Salle')
            .update({ 'reserved': 0 })
            .eq('id', data.salle.id)
            .select()
        
        const { dataCommande, errorCommande } = await supabase
            .from('Commande')
            .update({ 'notified': 1 })
            .eq('id', data.command_id)
            .select()
        
        if (error || errorCommande) {
            console.log(error);
            return;
        }
        handleClose();
    }

    const SupCommand = async() => {
        const {dataReservation, errorReservation } = await supabase
            .from('Reservation')
            .update({ validated: false})
            .eq('id', data.reservation_id)
            
        const {dataSalle, errorSalle } = await supabase
            .from('Salle')
            .update({ reserved: false})
            .eq('id', data.salle.id)

        const { error } = await supabase
            .from('Commande')
            .delete()
            .eq('reservation_fk', data.reservation_id)
        
        if (errorReservation || errorSalle || error) {
            console.log("Something went wrong with SupCommand !");
            return;
        }
        handleClose();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {data.is_out ? "Libération de salle !": "Suppression !"}
                </DialogTitle>
                <DialogContent>
                    {
                        data.is_out ? (
                            <DialogContentText id="alert-dialog-description">
                                voulez-vous libérer la salle <Typography color={'primary'} component='span'>{data.salle.name}</Typography> ?
                            </DialogContentText>
                        ): (
                            <DialogContentText id="alert-dialog-description">
                                voulez-vous supprimer la commande en cours ?
                            </DialogContentText>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>Annuler</Button>
                    {
                        data.is_out ? (
                            <Button onClick={librerSalle} color='error'>
                                Libérer
                            </Button>
                        ): (
                            <Button onClick={SupCommand} color='error'>
                                Supprimer
                            </Button>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}