import { Google } from '@mui/icons-material'
import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { SectionTypography } from '../Components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper'

export const Partner = () => {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
    pb={3}
    >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pt: 3
        }}>
            <SectionTypography variant='h5'>Nos parterners</SectionTypography>
            <SectionTypography variant='caption' color='secondary.dark'>Nous sommes parteners</SectionTypography>
        </Box>
        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box sx={{
                        width: '100%'
                    }}>
                        <Swiper
                        slidesPerView={isDownSm ? 1 : 3}
                        freeMode={true}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        centeredSlides={true}
                        >
                            <SwiperSlide>
                                <Box sx={{
                                    width: 250,
                                    height: 300,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <img style={{ width: 200 }} src="https://www.enabel.be/app/uploads/2022/06/enabel-logo-color.svg" alt='enabel-logo' />
                                </Box>
                                
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://www.esisalama.com/assets/img/actualite/img-19082023-111310.png" alt='esis-logo' />
                                </Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://scontent.ffbm1-1.fna.fbcdn.net/v/t39.30808-6/299650471_394572176120783_201612391145916221_n.png?_nc_cat=106&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeEwC-JLYzxX5WAn7jmh6ROtrKekMOJ3ZgWsp6Qw4ndmBd99K-_nqI-GykxojE1P-D2-BTWMHEJIN_Bac1Uys1e8&_nc_ohc=G_dKtTAtIWwAX9kQirE&_nc_zt=23&_nc_ht=scontent.ffbm1-1.fna&oh=00_AfDTAcVfZHm63jGXyZT44LIcNZvl5wvMglhc1mLkXHB8lg&oe=65905E22" alt='Radio Don bosco' />
                                </Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://encorimsarl.com/wp-content/uploads/2023/06/logo-a-modifier.jpg" alt='Radio Don bosco' />
                                </Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://upload.wikimedia.org/wikipedia/fr/1/1f/Radio_Maria_logo.png" alt='Radio Don bosco' />
                                </Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://congomonde.files.wordpress.com/2016/06/kyondo-radio-et-tele.jpg" alt='Radio Don bosco' />
                                </Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://www.donbosco-rdc.org/img/1618132552.png" alt='Radio Don bosco' />
                                </Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Box sx={{
                                        maxWidth: 250,
                                        height: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    
                                    <img style={{ width: 200 }} src="https://www.sdbaon.org/wp-content/uploads/2020/11/SDB.jpg" alt='Logo Salésien de Don Bosco' />
                                </Box>
                            </SwiperSlide>
                            
                        </Swiper>
                    </Box>
                </Stack>
        </Container>
    </Box>
  )
}

export const Partners = () => {
  return (
    <>
        <Box
            id='partenaires'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                my: 3
            }}
        >
            <SectionTypography variant='h5'>Nos parterners</SectionTypography>
            <SectionTypography variant='caption' color='secondary.dark'>Nous sommes parteners</SectionTypography>
        </Box>
        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='space-around'
            alignItems='center'
            >
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <img width={150} height={150} src="https://www.enabel.be/app/uploads/2022/06/enabel-logo-color.svg" alt='enabel-logo' />
                    </Box>
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        
                        <img width={150} height={150} src="https://scontent.ffbm1-1.fna.fbcdn.net/v/t39.30808-6/374182245_785174433608087_6757585111487316268_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFPNT-en9iOdc3Nh8ejBnfbYHoX0n5yVMBgehfSfnJUwC09XRMZGSDcu038Zm5eR7eVPOWDzWz5EccY6ns-BGiz&_nc_ohc=85-u4Rv437oAX_ywW5l&_nc_zt=23&_nc_ht=scontent.ffbm1-1.fna&oh=00_AfCQojO9VcesVFHD4L6m5KeIEn004bPJOR_bzQpMTHDIFA&oe=6565B7A6" alt='esis-logo' />
                    </Box>
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <Google sx={{
                            fontSize: '5rem'
                        }} />
                    </Box>
                </Stack>
        </Container>
    </>
  )
}
