import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


import { Box, Stack } from '@mui/material'
import { Paragraphe, SectionStartTypography, SectionTypography } from '../Components'

export const AnnonceItem = ({titre, description, image, dateEnd}) => {
    const date = new Date(dateEnd).toLocaleString('fr-FR', {dateStyle: "medium", timeStyle: "short"});
    return (
    <>
        <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
        >
            <Box sx={{
                maxWidth: 450,
            }}>
                <img alt="image" width='100%' style={{
                            backgroundSize: 'cover',
                            borderRadius: 10
                        }} src={image} />
            </Box>
            <Box
                maxWidth={500}
                height={310}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-around'}
            >
                <div>
                    <SectionStartTypography mb={1} variant="h6">{titre}</SectionStartTypography>
                    <Paragraphe>{description}</Paragraphe>
                </div>
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <CalendarMonthIcon color='primary' />
                    <SectionTypography variant='body1' color={'primary'} fontWeight={'bold'}>{date}</SectionTypography>
                </Box>
            </Box>
        </Stack>
    </>
  )
}
