import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Pagination, EffectCoverflow, Autoplay } from 'swiper'

import 'swiper/css'
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Paragraphe, SectionTypography } from '../Components';

export const SallesSlider = () => {
  return (
    <>
        <Box id='about' sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            my: 3
        }}>
            <SectionTypography variant='h5'>À propos</SectionTypography>
            <SectionTypography variant='caption' color='secondary.dark'>Ce que nous sommes</SectionTypography>
        </Box>
        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        autoplay={{
                            delay: 7500,
                            disableOnInteraction: false,
                        }}
                        coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination, Autoplay]}
                        >
                            <SwiperSlide>
                                <img alt="image" width='100%' style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10
                                }} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420996/safina/IMG_7776_at03c7.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' height={300} style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10,
                                    objectFit: 'cover'
                                }} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1700727718/safina/rqsjf1ertaq3wjqe7mxe.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' height={300} style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10,
                                    objectFit: 'cover'
                                }} src='https://res.cloudinary.com/mouss/image/upload/v1701764995/safina/wba0udtj0i0qtaxxflx1.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10
                                }} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1700547596/safina/ch7b5dtqswm29fdl9smy.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' height={300} style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10,
                                    objectFit: 'scale-down'
                                }} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1700727715/safina/s1gtaufnuw5hurxvsooi.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' height={300} style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10,
                                    objectFit: 'cover'
                                }} src='https://res.cloudinary.com/mouss/image/upload/v1701764990/safina/zzkyvtzcioepnsyh1vl3.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10
                                }} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420523/safina/img-2181_nqi8su.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10
                                }} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420331/safina/kab_d-128_p9t4tc.jpg' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="image" width='100%' style={{
                                    backgroundSize: 'cover',
                                    borderRadius: 10
                                }} src='https://res.cloudinary.com/mouss/image/upload/v1701764984/safina/xfr6etcuphlvffldsj72.jpg' />
                            </SwiperSlide>
                        </Swiper>
                    </Box>
                    <Box
                    maxWidth={500}
                    >
                        <Paragraphe variant='body1'>Avec une bibliothèque bien fournie en ouvrages culturels et religieux et un parc naturel mise a la disposition des jeunes pour la lecture! L'ambiance de la maison est chaleureuse et bienveillante.</Paragraphe>
                        <Paragraphe variant='body1'>Cette maison  est pour nous (jeunes) un lieu de ressourcement et de croissance personnelle, où l'on se sent bienvenu et accompagné tout au long de son parcours dans les différentes cellules (Cela nous permet de découvrir et exprimer nos talent).</Paragraphe>
                        <Paragraphe variant='body1'>ancien ou nouveau membre, nous portons tous a cœur Safina, et faisons tout pour que l'arche continue toujours de flotter, depuis plus de 30 Ans.</Paragraphe>
                    </Box>
                </Stack>
        </Container>
    </>
  )
}
