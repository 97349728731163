import { Avatar, Box, Card, CardContent, Container, Icon, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper'
import { Paragraphe, SectionTypography } from '../Components'

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

export const AvisComponent = () => {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
    bgcolor='secondary.main'
    pb={3}
    >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pt: 3
        }}>
            <SectionTypography variant='h5'>Avis</SectionTypography>
            <SectionTypography variant='caption' color='secondary.dark'>Ce qu'ils pensent de nous</SectionTypography>
        </Box>
        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box sx={{
                        width: '100%'
                    }}>
                        <Swiper
                        slidesPerView={isDownSm ? 1 : 3}
                        spaceBetween={30}
                        freeMode={true}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        >
                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        Bonjour, Ici Gabby Umba étudiant en génie logiciel à l’université protestante de Lubumbashi (UPL). 
Safina est une maison que je fréquente depuis bientôt 4 ans , j’y vais régulièrement pour travailler sur mes différents travaux à la faculté et mes différents projets personnels. A safina je trouve la tranquillité et la concentration nécessaire pour fournir un bon travail . 
En dehors de ça , je participe aux différentes activités qu’organise cette maison et c’est toujours très intéressant d’y prendre part.
En passant je tiens à remercier les salésiens de Don Bosco pour cette œuvre en faveur de la jeunesse.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Gabby UMBA">
                                                <Avatar src='https://res.cloudinary.com/mouss/image/upload/v1700729537/safina/gyvqwtqvxamghlmcrvck.jpg' />
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        je suis membre de la maison safina depuis 2019. je suis heureux de connaître cette maison qui n'est non seulement un espace vert de méditation ou d'étude mais "toute une école de réflexion pour jeunes" je pense et je reste persuadé que tous les jeunes  comme moi devraient connaître  la maison Safina et d'y être membre pour bénéficier également  de son immensité de richesses.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Fidel KAOMA">
                                                <Avatar>FK</Avatar>
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        Je suis une ancienne étudiante d'esis j'étais à la recherche d'un endroit où le calme est une règle d'or où je pouvais respirer le calme de la nature pour toute inspiration dans ma lecture et j'ai fait la rencontre de ce bel espace d'étude qui est le parc culturel safina où la convivialité est au rendez-vous.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Ferlay KALUFYA">
                                                <Avatar src="https://res.cloudinary.com/mouss/image/upload/v1700549106/safina/wqqg59ika170wq4a7agy.jpg" />
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        Je suis une étudiante en troisième année de licence , j'ai toujours voulu passer du temps dans un endroit calme , pendant mes pauses entrain de bosser , être concentré sur moi-même , mes études  tout en étant  aisé. j'ai découvert le parc Safina; un espace paisible que je connaisse.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Divine">
                                                <Avatar src="https://res.cloudinary.com/mouss/image/upload/v1700641410/safina/nedsnnczjinzwpanvaef.jpg" />
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        La maison safina est un endroit incontournable pour tous les amoureux de la lecture.
Elle offre un large choix de livres, un espace de lecture agréable, ainsi que des activités culturelles variées.
C'est un lieu de vie et de partage, qui contribue à enrichir la communauté.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Tchek KAPEND">
                                                <Avatar>TK</Avatar>
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        Je fus étudiant à l'ESIS. De mon temps, l'espace SAFINA était le seul endroit calme où l'on pouvait entrer en symbiose avec la nature et avoir un bon moment de lecture et de reflexion profonde. 
Que ça me manque aujourd'hui !
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Jacques NDAYI">
                                                <Avatar src="https://res.cloudinary.com/mouss/image/upload/v1700641984/safina/z2uhirvcbioihaxjk2o2.jpg" />
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        La sécurité, la sécurité de ma personne et de mes biens est l’un de principaux critères pour lequel j’ai choisi Safina.
                                        De l’air frais, de l’herbe et des arbres, des conditions rêvées  favorisant un bon travail, je l’experimente chaque fois que je suis a safina.
                                        Les coupures électriques peuvent être un véritable obstacle pour son travail, mais grâce à Safina j’ai pu remédier à cela.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Emmanuel">
                                                <Avatar src="https://res.cloudinary.com/mouss/image/upload/v1700642344/safina/rlei72i6osxdohfhwkqq.jpg" />
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <CardContent>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Icon color='primary'>
                                                <FormatQuoteIcon />
                                            </Icon>
                                        </Box>
                                        <Typography variant='body2' my textAlign='center'>
                                        Safina est une maison culturelle à Lubumbashi, en RDC, avec une bibliothèque et un espace naturel. C'est un lieu calme et beau au cœur de la ville, idéal pour les amoureux de la lecture. Ils organisent des événements littéraires et des rencontres avec des auteurs. Venez découvrir ce trésor caché et laissez-vous emporter par la magie de la culture et de la nature.
                                        </Typography>
                                        <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        >
                                            <Tooltip title="Emmanuel">
                                                <Avatar src="https://res.cloudinary.com/mouss/image/upload/v1700727723/safina/p3du2ffsqowi1dewckl3.jpg" />
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            
                        </Swiper>
                    </Box>
                </Stack>
        </Container>
    </Box>
  )
}
