import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { Paragraphe, SectionTypography } from '../Components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link, NavLink } from 'react-router-dom';

export const ServiceComponent = () => {
  return (
    <Box
        id='services'
        sx={{
            bgcolor: 'secondary.main',
            py: 4
        }}
    >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            my: 3
        }}>
            <SectionTypography variant='h5'>Services</SectionTypography>
            <SectionTypography variant='caption' color='secondary.dark'>Ce que nous proposons</SectionTypography>
        </Box>

        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <img style={{
                            borderBottomRightRadius: 60,
                            borderTopLeftRadius: 60,
                        }} alt='Image' width={'99%'} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420505/safina/kab_d-14_nvfere.jpg' />
                    </Box>
                    <Box
                    maxWidth={500}
                    >
                        <Typography variant='subtitle1' sx={{ fontWeight: "bold", marginBottom: 2 }}>&#9866; Activités culturelles &#9866;</Typography>
                        <Paragraphe variant='body1'>Développer les connaissances et la compréhension des individus dans différents domaines culturels, tels que l'art, la littérature, la musique, le théâtre, le cinéma, l'histoire, la philosophie, etc.</Paragraphe>
                        <Paragraphe variant='body1'>Permettre aux individus d'exprimer leur créativité et leur sensibilité artistique.</Paragraphe>
                        <Paragraphe variant='body1'>Des salles de spectacle dans des théâtres, des salles de concert ou des centres culturels.</Paragraphe>
                        <Link to='/cultures'>

                        <Button>En savoir plus <ArrowForwardIcon /></Button>
                        </Link>
                    </Box>
                </Stack>
        </Container>

        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column-reverse',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box
                        maxWidth={500}
                    >
                        <Typography variant='subtitle1' sx={{ fontWeight: "bold", marginBottom: 2 }}>&#9866; Bibliothèque &#9866;</Typography>
                        <Paragraphe variant='body1'>La bibliothèque a pour mission de favoriser l'accès à la culture, à l'information et à la connaissance. Elle propose un large choix de documents, des livres aux magazines.</Paragraphe>
                        <Paragraphe variant='body1'>La bibliothèque offre également un espace de travail et de détente, où vous pouvez venir lire, étudier ou simplement vous relaxer.</Paragraphe>
                        <Paragraphe variant='body1'>Nous vous invitons à venir découvrir notre bibliothèque. Vous y trouverez un lieu convivial et accueillant, où vous pourrez apprendre, vous divertir et vous enrichir.</Paragraphe>
                        <Link to='/bibliotheque'>

                        <Button>En savoir plus <ArrowForwardIcon /></Button>
                        </Link>
                    </Box>
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <img style={{
                            borderBottomLeftRadius: 130,
                            borderTopLeftRadius: 130
                        }} alt='Image' width={'99%'} src='https://images.pexels.com/photos/1370298/pexels-photo-1370298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                    </Box>
                </Stack>
        </Container>

        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <img style={{
                            borderRadius: 130
                        }} alt='Image' width={'99%'} src='https://images.pexels.com/photos/1181403/pexels-photo-1181403.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                    </Box>
                    <Box
                    maxWidth={500}
                    >
                        <Typography variant='subtitle1' sx={{ fontWeight: "bold", marginBottom: 2 }}>&#9866; Location de salles &#9866;</Typography>
                        <Paragraphe variant='body1'>La location de salle est une solution idéale pour organiser un événement, qu'il s'agisse d'une réunion, d'un séminaire, d'une conférence, d'un mariage ou d'un autre type d'événement.</Paragraphe>
                        <Paragraphe variant='body1'>Nous tenons compte de vos besoins et de vos exigences.</Paragraphe>
                        <Paragraphe variant='body1'>Pour en savoir plus sur notre service de location de salle, n'hésitez pas à nous contacter. Nous serons ravis de répondre à toutes vos questions et de vous aider à organiser votre événement.</Paragraphe>
                        <Link to={'/salles'}>
                             <Button>En savoir plus <ArrowForwardIcon /></Button>
                        </Link>
                    </Box>
                </Stack>
        </Container>

        {/**
        <Container>
            <Stack container my={6}
            direction={{
                xs: 'column-reverse',
                md: 'row'
            }}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            >
                    <Box
                    maxWidth={500}
                    >
                        <Paragraphe variant='body1'>Financial Service Landing Page designed by Saiful Islam Suzon for Readyui. Connect with them on Dribbble; the global community for designers and creative professionals.</Paragraphe>
                        <Paragraphe variant='body1'>Financial Service Landing Page designed by Saiful Islam Suzon for Readyui. Connect with them on Dribbble; the global community for designers and creative professionals.</Paragraphe>
                        <Paragraphe variant='body1'>Financial Service Landing Page designed by Saiful Islam Suzon for Readyui. Connect with them on Dribbble; the global community for designers and creative professionals.</Paragraphe>
                    </Box>
                    <Box sx={{
                        maxWidth: 450,
                    }}>
                        <img style={{
                            borderTopLeftRadius: 140,
                        }} alt='Image' width={'99%'} src='https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420552/safina/img-2369_oupd5f.jpg' />
                    </Box>
                </Stack>
        </Container> */}
    </Box>
  )
}
