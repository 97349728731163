import { Box, CssBaseline, Modal, SpeedDial, SpeedDialAction, SpeedDialIcon, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { lightTheme } from '../../ThemeComponents';
import { ClientPage } from './content/ClientPage';
import { HistoriquePage } from './content/HistoriquePage';
import { HomePage } from './content/HomePage';
import { ReservationPage } from './content/ReservationPage';
import { SallesPages } from './content/SallesPage';
import { DrawerSide } from './Drawer';
import { ToolBar } from './ToolBar';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { ReservationForm } from './ReservationForm';
import CampaignIcon from '@mui/icons-material/Campaign';
import { CampagneForm } from './CampagneForm';
import { resend } from '../../config/ResendClient';
import supabase from '../../config/SupabaseClient';
import { permissionEmail } from '../Components';

export const Dashboard = ({ open, handleDrawerOpen, handleDrawerClose}) => {
    const [openModal, setOpenModal] = useState(false);
    const [openCampagneModal, setOpenCampagneModal] = useState(false);
    const [changed, setChanged] = useState(false);

    const [email, setEmail] = useState(null);

    const reserveEvent = async (name, firstName, description, mail, dateBegin, dateEnd, salleId, nb) => {
        const { data, error } = await supabase
                .from('Reservation')
                .insert([
                    {
                        client_name: name,
                        client_first_name: firstName,
                        description: description,
                        email: mail,
                        date_begin: dateBegin,
                        date_end: dateEnd,
                        validated: 0,
                        salle_fk: salleId,
                        notified: 0,
                        nombre: nb
                    },
                ])
                .select()
                setChanged(!changed);
    }

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
        setEmail(session.user.email)
        })
    }, []);

    return (
        // <Router>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <ToolBar open={open} handleDrawerOpen={handleDrawerOpen}/>
                <DrawerSide open={open} handleDrawerClose={handleDrawerClose} theme={lightTheme} />
                <Outlet context={[changed, setChanged]}/>
                <Tooltip title='Réserver'>
                    <SpeedDial
                        ariaLabel='Ajouter une réservation'
                        sx={{ position: 'absolute', bottom: {
                            xs: '-15rem',
                            md: '1rem'
                        }, right: {
                            xs: '-5rem',
                            md: '1rem'
                        } }}
                        icon={<SpeedDialIcon />}
                    >
                        <SpeedDialAction
                            onClick={() => setOpenModal(true)}
                            key={"reserver"}
                            icon={<PostAddIcon />}
                            tooltipTitle={"Formulaire de réservation"}
                        />
                        {
                            permissionEmail.includes(email) && (
                                <SpeedDialAction
                                    onClick={() => setOpenCampagneModal(true)}
                                    key={"Ajouter Annonce"}
                                    icon={<CampaignIcon />}
                                    tooltipTitle={"Formulaire d'annonce"}
                                />
                            )
                        }
                    </SpeedDial>
                </Tooltip>
                <Modal
                    open={openCampagneModal}
                    onClose={() => setOpenCampagneModal(false)}
                >
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        p: 4
                    }}
                    width= {{
                        xs: '85%',
                        md: '65%'
                    }}
                    >
                        <CampagneForm setOpenModal={setOpenCampagneModal} />
                    </Box>
                </Modal>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        p: 4
                    }}
                    width= {{
                        xs: '85%',
                        md: '65%'
                    }}
                    >
                        <ReservationForm reserveEvent={reserveEvent} setOpenModal={setOpenModal} />
                    </Box>
                </Modal>
            </Box>
        // </Router>
    )
}
