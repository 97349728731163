import supabase from '../../../config/SupabaseClient';
import TabsHomePage from './TabsHomePage';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const mois = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

const valueFormatter = (value) => `${value}$`;


export const HomePage = ({open}) => {
  const [gain, setGain] = useState(null);
  const [reserveNb, setReserveNb] = useState(null);
  const [datas, setDatas] = useState([]);

  const fetchPriceMonth = async () => {
    let { data, error } = await supabase.rpc('get_prix_par_mois_annee')
    if (data) {
      console.log(data)
      data.filter((item) => {item.mois = mois[item.mois-1]})
      const categories = data.reduce((acc, objet) => {
        const cle = objet.mois;
        if (!acc.hasOwnProperty(cle)) {
          acc[cle] = 0;
        }
        console.log(acc)
        acc[cle] += objet.prix;
        return acc;
      }, {});
      const aray = Object.entries(categories);
      let resultat = []
      
      aray.forEach((e) => {
        resultat.push({month: e[0], data: e[1]})
      })

      console.log(resultat)
      setDatas(resultat)

    }

    if (error) {
      console.log(error)
    }

  }

  const fetchReserveNb = async () => {
    setReserveNb(0);
    const {data, error} = await supabase
      .from('Reservation')
      .select(`id`)
      .gte('date_begin', new Date(new Date().setHours(0,0,0,0)).toUTCString())
      .eq('validated', 0)
      // .select(`id, Salle.name`)

    setReserveNb(data.length);

    if (error) {
      console.log(error)
    }

    if (data) {
      console.log(data);
    }
  }

  const fetchGain = async () => {
    setGain(0)
    const {data, error} = await supabase
      .from('Commande')
      .select(`id, price, created_at`)
      .gte('created_at', new Date(new Date().setHours(0,0,0,0)).toUTCString())
      // .select(`id, Salle.name`)
    let price = 0;

    data.map((row) => (
      price =  price + row.price

    ))
    setGain(price);

    if (error) {
      console.log(error)
    }

    if (data) {
      console.log(data);
    }
  }

  useEffect(() => {
    fetchGain();
    fetchReserveNb();
    fetchPriceMonth();
  }, []);

  return (
    <Main open={open}>
        <DrawerHeader />
        <Typography variant="h6">Acceuil</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card sx={{
              bgcolor: 'secondary.light'
            }}>
              <CardContent>
                <Typography variant='subtitle2'>Gains générés du jour</Typography>
                <Typography variant='h4'>$ {gain}</Typography>
                <Typography mt={3}>Dernière entrée</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{
              bgcolor: 'secondary.light'
            }}>
              <CardContent>
                <Typography variant='subtitle2'>Nombre de reservation en attente</Typography>
                <Typography variant='h4'>{reserveNb}</Typography>
                <Typography mt={3}>Dernière reservation</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{
              bgcolor: 'secondary.light'
            }}>
              <CardContent>
                <Typography variant='subtitle2'>Nombre de salles</Typography>
                <Typography variant='h4'>5</Typography>
                <Typography mt={3}>Dernière reservation</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <TabsHomePage />
          </Grid>
          <Grid item xs={12} md={6}>
            {
              (datas.length !== 0) && (
                <BarChart
                  dataset={datas}
                  xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                  series={[{ dataKey: 'data', label: 'Recettes', valueFormatter }]}
                  // width={500}
                  height={500}
                />
              )
            }
          </Grid>
        </Grid>
    </Main>
  )
}