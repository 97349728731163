import { createTheme } from "@mui/material";


export const lightTheme = createTheme({
    palette: {
      primary: {
        main: '#0B4619'
      },
      secondary: {
        main: '#C7E9B0'
      },
      white: {
        main: '#ffffff'
      },
      mode: 'light'
    },
    typography: {
      fontFamily: [
        'Open Sans',
        'sans-serif',
      ].join(','),
    }
})
