import React, { useRef, useState } from 'react';
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import '../../App.css';


export const BannerComponent = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
    bgcolor='secondary.light'
    py={6}
    >
        <Container>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                }
            }}
            >
                {
                    matches && (
                        <Swiper
                            spaceBetween={30}
                            effect={'fade'}
                            autoplay={{
                                delay: 7500,
                                disableOnInteraction: false,
                            }}
                            observer={false}
                            pagination={false}
                            modules={[Autoplay, EffectFade, Navigation, Pagination]}
                            className="mySwiper"
                            width={480}
                        >
                            <SwiperSlide>
                            <img
                            height={400}
                            style={{
                                borderRadius: 130,
                                backgroundColor: '#C7E9B0',
                                objectFit: 'cover'
                            }} src="https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689421078/safina/IMG_7784_ykuadm.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <img
                            height={400}
                            style={{
                                borderRadius: 130,
                                borderBottomRightRadius: 0,
                                backgroundColor: '#C7E9B0',
                                objectFit: 'cover'
                            }}
                            src="https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420955/safina/IMG_7773_jkmsyn.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <img
                            height={400}
                            style={{
                                borderRadius: 130,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                backgroundColor: '#C7E9B0',
                                objectFit: 'cover'
                            }}
                            src="https://res.cloudinary.com/mouss/image/upload/w_450,f_auto/v1689420996/safina/IMG_7776_at03c7.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <img
                            height={400}
                            style={{
                                borderRadius: 130,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                backgroundColor: '#C7E9B0',
                                objectFit: 'cover'
                            }}
                            src="https://res.cloudinary.com/mouss/image/upload/v1701764987/safina/jorwplgojgrpfpccd7ge.jpg" />
                            </SwiperSlide>
                        </Swiper>
                    )
                }
                <Stack spacing={3} maxWidth={500} display={{ xs: 'flex', md: 'inline' }}>
                    <Typography variant='h3' sx={{
                        fontWeight: 'bold'
                    }}>Formation culturelle et chrétienne</Typography>
                    <Typography>Former des jeunes personnes à une culture ouverte, riche et créative, ainsi qu'à une spiritualité chrétienne authentique et vivante caractérise Safina depuis plus de 30 Ans . </Typography>
                    <Button variant='contained' href='#contact'>Contactez-nous</Button>

                </Stack>
            </Box>
        </Container>
    </Box>
  )
}
