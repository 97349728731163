import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from 'react'
import supabase from '../../config/SupabaseClient';

const options = [
    { label: 'Le premier', id: 1 },
    { label: 'Le deuxième', id: 2}
]

export const ReservationForm = ({ setOpenModal, reserveEvent }) => {
    const [firstName, setFirstName] = useState(null);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [email, setEmail] = useState(null);
    const [dateBegin, setDateBegin] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [salle, setSalle] = useState(null);
    const [nb, setNb] = useState(null)

    const [salles, setSalles] = useState([]);
    

    const getSalles = async () => {
        let { data, error } = await supabase
            .from('Salle')
            .select('*')
            .eq('reserved', 0)

        if (data) {
            data.filter((column) =>{
                column.label = column.name;
            })
            setSalles(data);
            console.log(data);
        }
        if (error) {
            setSalles([]);
            console.log(error);
        }
    }

    const reserve = () => {
        if (salle != null) {

            reserveEvent(name, firstName, description, email, dateBegin, dateEnd, salle.id, nb)
            // const { data, error } = await supabase
            //     .from('Reservation')
            //     .insert([
            //         {
            //             client_name: name,
            //             client_first_name: firstName,
            //             description: description,
            //             email: email,
            //             date_begin: dateBegin,
            //             date_end: dateEnd,
            //             validated: 0,
            //             salle_fk: salle.id,
            //             notified: 0,
            //             nombre: nb
            //         },
            //     ])
            //     .select()
        }
    }

    // const setNullAtt = () => {
    //     setFirstName(null);
    //     setName(null);
    //     setDescription(null);
    //     setDateBegin(null)
    //     setDateEnd(null)
    //     set
    // }

    const submit = () => {
        reserve();
        setOpenModal(false);
    }

    useEffect(() => {
        getSalles();
        console.log(salle)
        
    }, []);

    
  return (
    <>
        <Typography gutterBottom variant='h5' color='primary' textAlign='center'>
            Formulaire De Réservation
        </Typography>
        <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction='column'
                        spacing={2}
                    >
                        <TextField
                            label="Prénom du client"
                            placeholder='Prénom'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            label="Nom du client"
                            placeholder='Nom'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            label="Description de l'évenement"
                            placeholder='Description'
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction='column'
                        spacing={1}
                    >
                        <TextField
                            label="E-mail du client"
                            placeholder='E-mail'
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={['DateTimePicker', 'DateTimePicker']}
                            >
                                <Stack direction='row' spacing={1}>
                                    <DateTimePicker label='Date début' value={dateBegin} onChange={(newValue) => setDateBegin(newValue)} />
                                    <DateTimePicker label='Date fin' value={dateEnd} onChange={(newValue) => setDateEnd(newValue)} />
                                </Stack>
                            </DemoContainer>
                        </LocalizationProvider>
                        
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={salles.length !== 0 ? salles: [{label:"Aucune salle à valider"}]}    
                            value={salle}
                            onChange={(e, v) => setSalle(v)}
                            renderInput={(params) => <TextField {...params} label="La Salle" placeholder='Salle' />}
                            />
                        
                        <TextField
                            label="Personnes attendues"
                            placeholder='Nombre'
                            type={'number'}
                            value={nb}
                            onChange={(e) => setNb(e.target.value)}
                        />
                    </Stack>
                </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    pt: 3
                }}
            >
                <Button variant='contained' onClick={() => submit()}>Réserver</Button>
            </Box>
        </Grid>
    </>
  )
}
