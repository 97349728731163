import { Box, Typography } from '@mui/material'
import React from 'react'

export const Facture = ({num_reservation, prix, date_debut, date_fin, salle}) => {
    const dateNow = new Date().toLocaleDateString()
    return (
    <Box
        width={300}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <Typography fontWeight={'bold'}>Maison safina</Typography>
                <Box
                    sx={{
                        borderLeft: 2,
                        pl: 3
                    }}
                >
                    <Typography variant='caption'>N°170 A.V: Kasangulu</Typography>
                    <br />
                    <Typography variant='caption'>maisonsafina@gmail.com</Typography>
                    <br />
                    <Typography variant='caption'>+24 399 564 218 4</Typography>
                </Box>
            </Box>
            <img src='http://localhost:3000/logo.png' alt='logo-facture' width={65} />
        </Box>
        <Typography variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center', my: 3 }}>LOCATION DE SALLE</Typography>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                borderBottom: 2,
                borderTop: 2
            }}
        >
            <Box>
                <Typography variant='body1'>N° Facture</Typography>
                <Typography variant='body2' fontWeight={'bold'}>{num_reservation}</Typography>
            </Box>
            <Box>
                <Typography variant='body1'>Date</Typography>
                <Typography variant='body2' fontWeight={'bold'}>{dateNow}</Typography>
            </Box>
            <Box>
                <Typography variant='body1'>Acompte</Typography>
                <Typography variant='body2' fontWeight={'bold'}>{prix} FC</Typography>
            </Box>
        </Box>
        <Box
            my={1}
        >
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                my={1}
            >
                <Typography variant='body2'>Date début :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>{date_debut}</Typography>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                my={1}
            >
                <Typography variant='body2'>Date fin :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>{date_fin}</Typography>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                my={1}
            >
                <Typography variant='body2'>Salle :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>{salle}</Typography>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'flex-end'}
                my={2}
            >
                <Typography>Total :</Typography>
                <Typography fontWeight={'bold'}>{prix} FC</Typography>
            </Box>
        </Box>
    </Box>
  )
}
