import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import React, { useState } from 'react'
import supabase from '../../config/SupabaseClient'

export const UserReservationFrom = ({setOpenModal, salleId}) => {
    const [dateBegin, setDateBegin] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);

    const handleSubmit = async(e) => {
        e.preventDefault();
        const form = e.target
        const { data, error } = await supabase
        .from('Reservation')
        .insert([
            {
                client_name: form.nom.value,
                client_first_name: form.prenom.value,
                description: form.description.value,
                email: form.email.value,
                date_begin: dateBegin,
                date_end: dateEnd,
                validated: 0,
                salle_fk: salleId,
                notified: 0,
                nombre: form.nombre.value
            },
        ])
        .select()

        if (data) {
            console.log(data)
            setOpenModal(false);
        }

        if (error) {
            alert(error.message)
            setOpenModal(false)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const form = e.target
        alert(
            `Nom : ${form.nom.value}
            \nPrenom : ${form.prenom.value}
            \nDescription : ${form.description.value}
            \nEmail : ${form.email.value}
            \nNombre : ${form.nombre.value}`
        )
        setOpenModal(false);
    }

  return (
    <>
        <form onSubmit={handleSubmit}>
            <Typography gutterBottom variant='h5' color='primary' textAlign='center'>
                Formulaire De Réservation
            </Typography>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack
                            direction='column'
                            spacing={2}
                        >
                            <TextField
                                label="Prénom du client"
                                placeholder='Prénom'
                                name='prenom'
                                required
                            />
                            <TextField
                                label="Nom du client"
                                placeholder='Nom'
                                name='nom'
                                required
                            />
                            <TextField
                                label="Description de l'évenement"
                                placeholder='Description'
                                multiline
                                rows={4}
                                name='description'
                                required
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack
                            direction='column'
                            spacing={1}
                        >
                            <TextField
                                label="E-mail du client"
                                placeholder='E-mail'
                                type='email'
                                name='email'
                                required
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['DateTimePicker', 'DateTimePicker']}
                                >
                                    <Stack direction='row' spacing={1}>
                                        <DateTimePicker disablePast label='Date début' value={dateBegin} onChange={(newValue) => setDateBegin(newValue)} />
                                        <DateTimePicker label='Date fin' value={dateEnd} onChange={(newValue) => setDateEnd(newValue)} />
                                    </Stack>
                                </DemoContainer>
                            </LocalizationProvider>
                            
                            <TextField
                                label="Personnes attendues"
                                placeholder='Nombre'
                                type={'number'}
                                name='nombre'
                                required
                            />
                        </Stack>
                    </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        pt: 3
                    }}
                >
                    <Button variant='contained' type='submit'>Réserver</Button>
                </Box>
            </Grid>
        </form>
    </>
  )
}
