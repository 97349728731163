import React, { useState } from 'react'

import { NavbarLanding } from '../landingPage/NavbarLanding'
import { FooterComponent } from '../landingPage/FooterComponent'
import { Box, Container, Modal, Typography } from '@mui/material'
import { UserReservationFrom } from './UserReservationFrom';
import { SalleCard } from './SalleCard';

export const SallePage = ({session}) => {
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);

    const handleClose = () => {
        setOpenModal(false);
        setId(null);
    }

    const salles = [
        {
            image: "https://res.cloudinary.com/mouss/image/upload/v1689420479/safina/kab_d-13_wqfkxk.jpg",
            title : "Salle salama",
            text : "La salle Salama est une salle spacieuse et modulable, capable d'accueillir une variété d'événements. La salle peut accueillir jusqu'à 550 personnes en configuration conférence sans tables. En configuration conférence avec tables, la capacité est de 300 personnes.",
            id: 7
        },
        {
            image: "https://res.cloudinary.com/mouss/image/upload/v1689420479/safina/kab_d-13_wqfkxk.jpg",
            title : "Maria Nyota ya asubuyi",
            text : "La salle Maria nyota ya asubuyi est une salle spacieuse et modulable, capable d'accueillir une variété d'événements, des conférences aux mariages. La salle peut accueillir jusqu'à 200 personnes en configuration conférence sans tables, ou jusqu'à 150 personnes en configuration mariage. En configuration conférence avec tables, la capacité est de 80 personnes.",
            id: 5
        },
        {
            image: "https://res.cloudinary.com/mouss/image/upload/v1689420479/safina/kab_d-13_wqfkxk.jpg",
            title : "Salle pastorale",
            text : "La salle Pastorale est une salle spacieuse et modulable, capable d'accueillir une variété d'événements. La salle peut accueillir jusqu'à 60 personnes en configuration conférence sans tables. En configuration conférence avec tables, la capacité est de 23 personnes.",
            id: 6
        },
        {
            image: "https://res.cloudinary.com/mouss/image/upload/v1689420479/safina/kab_d-13_wqfkxk.jpg",
            title : "Salle saint François",
            text : "La salle Saint François est une salle spacieuse et modulable, capable d'accueillir une variété d'événements. La salle peut accueillir jusqu'à 70 personnes en configuration conférence sans tables. En configuration conférence avec tables, la capacité est de 40 personnes.",
            id: 3
        },
        {
            image: "https://res.cloudinary.com/mouss/image/upload/v1689420479/safina/kab_d-13_wqfkxk.jpg",
            title : "Salle Hall",
            text : "La salle Hall est une salle spacieuse et modulable, capable d'accueillir une variété d'événements. La salle peut accueillir jusqu'à 40 personnes en configuration conférence sans tables. En configuration conférence avec tables, la capacité est de 20 personnes.",
            id: 4
        },
    ]

  return (
    <>
        <NavbarLanding />
        <Container>
        <Typography variant='h5' fontWeight={'bold'}>Salles de safina</Typography>
            {
                salles.map((item) => (
                    <SalleCard setOpenModal={setOpenModal} setId={setId} item={item} />
                ))
            }
        </Container>
        <FooterComponent session={session} />
        <Modal
            open={openModal}
            onClose={handleClose}
        >
            <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: 4
            }}
            width= {{
                xs: '85%',
                md: '65%'
            }}
            >
                <UserReservationFrom setOpenModal={setOpenModal} salleId={id} />
            </Box>
        </Modal>
    </>
  )
}
