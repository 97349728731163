import { CatchingPokemon, ContactSupport, History, Home, Info, Menu, Web } from '@mui/icons-material'
import { AppBar, Box, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import { useState } from 'react'

export const NavbarLanding = () => {
    const [open, setOpen] = useState(false);
  return (
    <AppBar color='background' position='sticky'>
        <Toolbar>
            <IconButton color='primary'>
                <img src='/logo.png' width={50} />
            </IconButton>
            <Stack direction='row' sx={{
                ml: 'auto',
                mr: 6,
                display: {
                xs: 'none',
                md: 'block'
                }
                }} spacing={4}>
                <Link variant='subtitle1' underline='none' href='/'>Accueil</Link>
                <Link variant='subtitle1' underline='none' href='#about'>À propos</Link>
                <Link variant='subtitle1' underline='none' href='#services'>Services</Link>
                <Link variant='subtitle1' underline='none' href='#partenaires'>Partenaires</Link>
                <Link variant='subtitle1' underline='none' href='#contact'>Contact</Link>
            </Stack>
            <IconButton 
            sx={{
                display: {
                md: 'none'
                }
            }}
            onClick={() => setOpen(true)}
            color='primary'
            >
                <Menu />
            </IconButton>
            <Drawer
            anchor='left'
            open={open}
            onClose={() => setOpen(false)}
            >
                <Box width={250}>
                    <List>
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Home color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={<Link href='#' color='primary'>Home</Link>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Info color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={<Link href='#about' color='primary'>À propos</Link>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <History color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={<Link href='#services' color='primary'>Services</Link>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Web color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={<Link href='#partenaires' color='primary'>Partenaires</Link>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <ContactSupport color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={<Link href='#contact' color='primary'>Contact</Link>} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Toolbar>
    </AppBar>
  )
}
