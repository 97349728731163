import { Brightness1, Brightness5, BrightnessMedium } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

export const TeamsComponent = () => {
  return (
    <Box
        position={'relative'}
    >
        <div
            style={{
                backgroundImage: "url('https://res.cloudinary.com/mouss/image/upload/h_450/v1689420552/safina/img-2369_oupd5f.jpg')",
                height: '400px',
                filter: "Brightness(70%)"
            }}
        >
        </div>
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
            color={'secondary.light'}
            position={'absolute'}
            bottom={10}
            width={'100%'}
            >
            <Typography variant='h2' textAlign={'center'}>Nous sommes Safina</Typography>
        </Box>
    </Box>
  )
}
