import { Box, Container, Stack } from '@mui/material'
import React from 'react'
import { Paragraphe, SectionTypography } from '../Components'
import { AnnonceItem } from './AnnonceItem'
import { Pagination, EffectCoverflow, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'


import 'swiper/css'
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

export const AnnonceComponent = ({ annonces }) => {
  return (
    <>
        <Box id='about' sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            my: 3
        }}>
            <SectionTypography variant='h5'>Annonces</SectionTypography>
            <SectionTypography variant='caption' color='secondary.dark'>Nos activités à venir</SectionTypography>
        </Box>
        <Container>
            <Swiper
            pagination={true}
            modules={[Pagination, Autoplay]}
            className="swiper"
            autoplay={{
                delay: 4500,
                disableOnInteraction: false,
            }}
            >
                {
                    annonces.map((item) => (
                        <div
                            key={item.id}
                        >
                            <SwiperSlide>
                                <AnnonceItem titre={item.titre} description={item.description} image={item.image} dateEnd={item.date_end} />
                            </SwiperSlide>
                        </div>
                    ))
                }
            </Swiper>
        </Container>
    </>
  )
}
