import React, { useEffect, useState } from 'react'
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { Paragraphe, SectionStartTypography } from '../Components'
import supabase from '../../config/SupabaseClient';

export const SalleCard = ({setOpenModal, item, setId}) => {
    const [reservations, setReservations] = useState([]);

    const handleButton = () => {
        setId(item.id);
        setOpenModal(true)
    }

    const getReservationSalle = async () => {
        let { data, error } = await supabase
        .from('Reservation')
        .select('*')
        .eq('salle_fk', item.id)
        .gte('date_begin', new Date().toUTCString())

        if (data) {
            console.log(data)
            setReservations(data)
        }
    }

    useEffect(() => {
        getReservationSalle();
    }, []);

  return (
    <>
        <Card
                sx={{
                    bgcolor: '#F6FAF7',
                    my: 3
                }}
            >
            <CardMedia
                sx={{ height: 400 }}
                image={item.image}
                title="Salle image"
            />
            <Box sx={{ mx: 4 }}>
                <CardContent>
                    <Typography variant='h6' fontWeight={'bold'}>{item.title}</Typography>
                    <Paragraphe>
                        {item.text}
                    </Paragraphe>
                    
                    <SectionStartTypography variant='body1'>Exemples d'utilisation</SectionStartTypography>
                    <Typography component={'ul'}>
                        <Typography component={'li'}>
                        Conférences
                        </Typography>
                        <Typography component={'li'}>
                        Réunions
                        </Typography>
                        <Typography component={'li'}>
                        Séminaires
                        </Typography>
                        <Typography component={'li'}>
                        Ateliers
                        </Typography>
                        <Typography component={'li'}>
                        Mariages
                        </Typography>
                        <Typography component={'li'}>
                        Anniversaires
                        </Typography>
                        <Typography component={'li'}>
                        Cocktails
                        </Typography>
                    </Typography>
                    <SectionStartTypography variant='body1'>Dates réservées</SectionStartTypography>
                    <Typography component={'ul'} color='red'>
                    {
                        reservations.map((item) => (
                            <Typography component={'li'}>{`De ${item.date_begin} à ${item.date_end}`}</Typography>
                        ))
                    }
                    </Typography>
                </CardContent>
                <CardActions>
                    {
                        true ? (
                            <Button onClick={() => handleButton()}>Réserver <EditCalendarIcon /></Button>
                        ): (
                            <Button color='error'>Réservée <EventBusyIcon /></Button>
                        )
                    }
                </CardActions>
            </Box>
        </Card>
    </>
  )
}
