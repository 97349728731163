import { Typography, styled, Box } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import supabase from "../../../config/SupabaseClient";
import { emptyCustom } from "./ReservationPage";

const drawerWidth = 240;

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'client_name', headerName: 'Nom', width: 130 },
  { field: 'client_first_name', headerName: 'Prénom', width: 130 },
  { field: 'created_at', headerName: 'Date', type: 'date', width: 130 },
  { field: 'description', headerName: 'Description', width: 130 },
  { field: 'email', headerName: 'Email', width: 100 },
  { field: 'date_begin', headerName: 'Date début', width: 100 },
  { field: 'date_end', headerName: 'Date fin', width: 100 },
  {
    field: 'price',
    headerName: 'Coût',
    type: 'number',
    width: 50,
  },
  {
    field: 'salle',
    headerName: 'Salle',
    width: 120,
  },
  {
    field: 'reservation_fk',
    headerName: 'Reservation ID',
    type: 'number',
    width: 50
  }
];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

export const HistoriquePage = ({open}) => {
  const [loading, setLoading] = useState(true);
  const [historique, setHistorique] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  const fetchHistorique = async () => {
    const {data, error} = await supabase
      .from('Commande')
      .select(`id, created_at, description, price, Reservation(id, client_name, client_first_name, email, date_begin, date_end, Salle (id, name) )`)
      // .select(`id, Salle.name`)

    data.filter((column) => {
      column.client_name = column.Reservation.client_name
      column.client_first_name = column.Reservation.client_first_name
      column.salle = column.Reservation.Salle.name
      column.reservation_fk = column.Reservation.id
      column.created_at = new Date(column.created_at)
      column.email = column.Reservation.email
      column.date_begin = new Date(column.Reservation.date_begin).toLocaleString('fr-FR', {dateStyle: "medium", timeStyle: "short"});
      column.date_end = new Date(column.Reservation.date_end).toLocaleString('fr-FR', {dateStyle: "medium", timeStyle: "short"});
      // console.log(column)
    })

    if (error) {
      setFetchError('Could not fetch the smoothies');
      setHistorique([]);
      console.log(error)
    }

    if (data) {
      setHistorique(data);
      setFetchError(null);
      console.log(data);
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchHistorique();
  }, []);

  return (
    <Main open={open}>
        <DrawerHeader />
        <Typography variant="h6">Historique des commandes validés</Typography>
        <Box height={400} width='100%'>
          <DataGrid
            rows={historique}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            loading={loading}
            slots={{
              noRowsOverlay: emptyCustom
            }}
          />
        </Box>
    </Main>
  )
}