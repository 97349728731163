import React from 'react'
import { Paragraphe } from '../Components'
import { NavbarLanding } from '../landingPage/NavbarLanding'
import { Box, Card, CardMedia, Container, Typography } from '@mui/material'
import { FooterComponent } from '../landingPage/FooterComponent'

export const BibliothequePage = ({session}) => {
  return (
    <>
        <NavbarLanding />
        <Container>
            <Card
                sx={{
                    bgcolor: '#F6FAF7',
                    mt: 1
                }}
            >
                <CardMedia
                    sx={{ height: 400 }}
                    image="https://images.pexels.com/photos/1370298/pexels-photo-1370298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    title="Bibliothèque"
                />
            </Card>
            <Box>
                <Typography variant='h6' my={2} fontWeight={'bold'}>Bibliothèque et Adhésion</Typography>
                <Paragraphe>La bibliothèque a pour mission de favoriser l'accès à la culture, à l'information et à la connaissance. Elle propose un large choix de documents, des livres aux magazines. La bibliothèque offre également un espace de travail et de détente, où vous pouvez venir lire, étudier ou simplement vous relaxer.</Paragraphe>
                <Paragraphe>Pour adhérer à la maison culturelle Safina, il faut remplir un formulaire d'adhésion et fournir deux photos passeport. Le montant de l'adhésion est généralement de 10 $ par an. L'adhésion est renouvelable chaque année.</Paragraphe>
                <Paragraphe>
                    L'adhésion donne d'office accès à:
                    <Typography component={'ul'}>
                        <Typography component={'li'}>
                            <Typography fontWeight={'bold'}>Une bibliothèque :</Typography> la bibliothèque offre un accès à une collection de livres, de magazines, de journaux et d'autres documents. Les adhérents peuvent emprunter des documents gratuitement. 
                        </Typography>
                        <Typography component={'li'}>
                            <Typography fontWeight={'bold'}>Un parc de lecture :</Typography>  le parc de lecture est un espace extérieur dédié à la lecture. Les adhérents peuvent y venir lire, se détendre et profiter de la nature.
                        </Typography>
                        <Typography component={'li'}>
                            <Typography fontWeight={'bold'}>Des activités culturelles :</Typography> la maison culturelle propose souvent des activités culturelles, telles que des concerts, des spectacles, des ateliers et des conférences. Les adhérents bénéficient généralement d'un tarif préférentiel pour ces activités.
                        </Typography>
                    </Typography>
                </Paragraphe>
                
                <Paragraphe>La maison safina accuelle tous les jeunes sans tenir compte de confession religieuse.</Paragraphe>
            </Box>
        </Container>
        <FooterComponent session={session} />
    </>
  )
}
