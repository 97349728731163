import React, { useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Box, Button, Card, CardMedia, CircularProgress, Stack, TextField, Typography, styled } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import supabase from '../../config/SupabaseClient';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export const CampagneForm = ({ setOpenModal, title="", description="", date=null, src=null }) => {
    const [image, setImage] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [loading, setLoading] = useState(false);

    // const file = new File(src)

    // alert(date)

    const handleChangeImage = (e) => {
        if (e.target.files) {
            const img = {}
            img.img = e.target.files[0]
            img.url = URL.createObjectURL(e.target.files[0])
            setImage(img)
        }
    } 

    const uplodFile = async () => {
        const { data, error } = await supabase
            .storage
            .from('annonces')
            .upload(`poster/${Date.now()}`, image.img)

        if (data) {
            console.log(data)
            return "https://jrjvckhhmlasahiftntj.supabase.co/storage/v1/object/public/annonces/" + data.path
        }
        if (error) {
            console.log(error)
            return null
        }
    }


    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        uplodFile()
            .then(async (url) => {

                const { data, error } = await supabase
                    .from('Annonce')
                    .insert([
                    { image: url, titre:  e.target.titre.value, description:  e.target.description.value, date_end: dateEnd },
                    ])
                    .select()
                
                if (data) {
                    console.log(data);
                    setLoading(false)
                    setOpenModal(false);
                }
                if (error) {
                    console.log(error)
                    alert(error)
                    setLoading(false)
                    setOpenModal(false)
                }
                    
            }
            )
            .catch((error) => {
                alert(error)
                setLoading(false)
            })
        
    }

    return (
    <>
        <form onSubmit={handleSubmit}>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                spacing={2}
            >
                <Typography gutterBottom variant='h5' color='primary' textAlign='center'>
                    Formulaire d'annonce
                </Typography>

                {
                    image && (
                        <Box>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height={200}
                                    image={image.url}
                                    alt="Paella dish"
                                />
                            </Card>
                        </Box>
                    )
                }
                <Box>
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        Choisir un fichier
                        <VisuallyHiddenInput type="file" name="image" accept="image/*" onChange={handleChangeImage} required />
                    </Button>
                </Box>

                <Box
                    width={{
                        xs: "100%",
                        md: "75%"
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <TextField defaultValue={title} fullWidth id="titre" required name='titre' label="Titre de l'annonce" variant="outlined" placeholder="Fête de l'indépendance" />
                </Box>

                <Box
                    width={{
                        xs: "100%",
                        md: "75%"
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <TextField
                        id="description"
                        label="Description de l'annonce"
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Une Description..."
                        name='description'
                        required
                        defaultValue={description}
                    />
                </Box>

                <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker label="Basic date time picker" required value={dateEnd} onChange={(newValue) => setDateEnd(newValue)} />
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>

                <Button variant='contained' disabled={loading} type='submit'>{ loading && <CircularProgress />} Publier</Button>
            </Stack>
        </form>
    </>
  )
}
