import { Typography, styled, Grid, Card, CardMedia, CardContent, CardActions, IconButton, CardHeader, Button, Modal } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import supabase from "../../../config/SupabaseClient";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

export const SallesPages = ({open}) => {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [salles, setSalles] = useState([]);

  const getSalles = async () => {
    const { data,error } = await supabase
      .from('Salle')
      .select('*')
    
    if (error) {
      console.log(error)
    }

    if (data) {
      console.log(data);
      setSalles(data);
    }
  }

  useEffect(() => {
    // const { data } = supabase
    //   .storage
    //   .from('salles')
    //   .getPublicUrl('show.jpg')

    getSalles();

  }, []);
  return (
    <Main open={open}>
        <DrawerHeader />
        <Typography variant="h6">Salles</Typography>
        <Grid container rowSpacing={2} columnSpacing={1}>
          {
            salles.map((salle) => (
              <Grid key={salle.id} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardMedia
                    component='img'
                    height={200}
                    image={salle.img}
                    alt={salle.name}
                  />
                  <CardContent>
                    <Typography variant="h6">{salle.name}</Typography>
                  </CardContent>
                  <CardActions>
                    {
                      salle.reserved ?
                        <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around'
                        }}
                        >
                          <IconButton disabled>
                            <EditIcon />
                          </IconButton>
                        
                          <IconButton color="error" onClick={() => setOpenCancelModal(true)}>
                            <HighlightOffIcon />
                          </IconButton>
                        </Box> :
                        <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around'
                        }}
                        >
                          <IconButton onClick={() => setOpenEditModal(true)}>
                            <EditIcon />
                          </IconButton>
                         
                          <IconButton color="error" disabled>
                            <HighlightOffIcon />
                          </IconButton>
                        </Box>
                    }
                  </CardActions>
                </Card>
              </Grid>

            ))
          }

        </Grid>
        <Confirmation alert="Alert d'annulation !" text='Voulez-vous annuler la réservation ?' openModal={openCancelModal} setOpenModal={setOpenCancelModal} />
        <Confirmation alert="Alert d'édition !" text='Voulez-vous editer la réservation ?' openModal={openEditModal} setOpenModal={setOpenEditModal} />
    </Main>
  )
}

const Confirmation = ({alert, text, openModal, setOpenModal}) => {
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        
          <Card
          sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              // p: 4
          }}
          >

              <CardHeader
                avatar={<ErrorOutlineIcon color="error" />}
                title={alert}
              />

              <CardContent>
                <Typography>{text}</Typography>
              </CardContent>
              <CardActions
              >
                  <Button>Oui</Button>
                  <Button onClick={() => setOpenModal(false)}>Non</Button>
                </CardActions>
          </Card>
      </Modal>
    </>
  )
}