import { Typography, styled, Box, Select, MenuItem, Button, Modal, AppBar, Card, CardContent, Grid, Stack, TextField, Autocomplete, CardActions, CardHeader, CircularProgress } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState, createContext, useContext } from "react";
import supabase from "../../../config/SupabaseClient";

const Context = createContext({
  salles: {},
  setSalles: () => {}
})

const FormModal = ({openModal, setOpenModal, row, setValidation}) => {
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);

  const [reservations, setReservations] = useState([])

  const createCommande = async () => {
    const { data, error } = await supabase
      .from('Commande')
      .insert([
        {
          description: description,
          price: price,
          reservation_fk: row.id,
          notified: 0
        },
      ])
      .select();
    
    setValidation();
    setOpenModal(false);
  }

  return (
    <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
    >
      
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 4
        }}
        width= {{
            xs: '85%',
            md: '65%'
        }}
      >
        <CardContent>Confirmer la réservation !</CardContent>
        <Stack
            direction='column'
            spacing={2}
        >
            <TextField
                label="Description de la Facture"
                placeholder='Description'
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
                label="Prix de la Réservation"
                placeholder='Prix'
                type='number'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
            />
            <TextField
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={`Rervation ID ${row.id}`}
            />
        </Stack>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                pt: 3
            }}
        >
          <Button variant='contained' onClick={() => createCommande()}>Valider</Button>
        </Box>
      </Card>
        
    </Modal>
  )
}

const SuppressionModal = ({openModal, setOpenModal, suppCommande}) => {
  const [load, setLoad] = useState(false);
  
  const handleConfirmation = () => {
    setLoad(true);
    suppCommande();
    // setLoad(false);
  }

  return (
    <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
    >
      
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          px: 4
        }}
        width= {{
            xs: '85%',
            md: '65%'
        }}
      >
        <CardHeader
          avatar={<ErrorOutlineIcon color="error" />}
          title="Suppression commande"
        />
        <CardContent>
          <Typography variant="body">Invalider cette commande ?</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => setOpenModal(false)}>Annuler</Button>
          <Button onClick={() => handleConfirmation()}>Confirmer {load && <CircularProgress size='small' />}</Button>  
        </CardActions>
      </Card>
        
    </Modal>
  )
}

const MessageModal = ({openModal, setOpenModal, suppCommande}) => {

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          px: 4
        }}
        width= {{
            xs: '85%',
            md: '65%'
        }}
      >
        <CardHeader
          avatar={<ErrorOutlineIcon color="error" />}
          title="Suppression commande"
        />
        <CardContent>
          <Typography variant="body">Cette salle est prise !</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => setOpenModal(false)}>OK</Button>
        </CardActions>
      </Card>
        
    </Modal>
  )
}

const CustomButton = (props) => {
  const [validate, setValidate] = useState(props.row.validated);
  const [openModal, setOpenModal] = useState(false);
  const [openSupModal, setOpenSupModal] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);

  const salleVal = useContext(Context);

  console.log(salleVal)

  const [hasCommand, setHasCommand] = useState(false);

  // console.log(props.row);

  const setValidationTrue = async () => {
    
    await supabase
      .from('Reservation')
      .update({ validated:  true})
      .eq('id', props.row.id)
    
    await supabase
      .from('Salle')
      .update({ reserved:  true})
      .eq('id', props.row.salle_fk)
    
    setValidate(true);
    
  }

  const suppCommande = async () => {

    await supabase
      .from('Reservation')
      .update({ validated: false})
      .eq('id', props.row.id)
    
    await supabase
      .from('Salle')
      .update({ reserved: false})
      .eq('id', props.row.salle_fk)

    const { error } = await supabase
      .from('Commande')
      .delete()
      .eq('reservation_fk', props.row.id)
    

    setValidate(false)

    setOpenSupModal(false)

  }

  const isHasCommand = async () => {
    const { data, error } = await supabase
      .from('Commande')
      .select('id, Reservation (id, description, Salle (id, name, alias))')
      .eq('reservation_fk', props.row.id);
    
    
    setHasCommand(data.length !== 0 ? true: false);
  }

  useEffect(() => {
    isHasCommand();
  }, []);

  const openAModal = () => {
    if (props.row.reserved === 'OUI') {
      setOpenModalMessage(true);
      console.log(`true: ${hasCommand}`);
    } else {
      setOpenModal(true);
      console.log(`false: ${hasCommand}`);
    }
  }

  return (
      <>
        {console.log(hasCommand)}
        {
          validate ?
          <Button variant="outlined" onClick={() => setOpenSupModal(true)}>Validé</Button> :
          <Button variant="outlined" onClick={() => openAModal()} color="error">Invalidé</Button>
        }
        <FormModal openModal={openModal} setOpenModal={setOpenModal} row={props.row} setValidation={setValidationTrue} />
        <SuppressionModal openModal={openSupModal} setOpenModal={setOpenSupModal} suppCommande={suppCommande} />
        <MessageModal openModal={openModalMessage} setOpenModal={setOpenModalMessage} />
      </>
  )
}

const CustomEditSalle = ({row}) => {
  const [val, setVal] = useState(row.salle_fk);
  const [salle, setSalle] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  const fetchSalle = async () => {
    const {data, error} = await supabase
        .from('Salle')
        .select(`id, name`)
    
    if (error) {
        setFetchError('Could not fetch the smoothies');
        setSalle([]);
        console.log(error)
      }
  
    if (data) {
      setSalle(data);
      setFetchError(null);
    }
  }

  const updateSalle = async() => {
    const {data, error} = await supabase
        .from('Reservation')
        .update({
          salle_fk: val
        })
        .eq('id', row.id)
    
      console.log(error)
  
      // console.log(data);
  }

  useEffect(() => {
    fetchSalle();
    updateSalle();
  }, [val]);


  return (
    <Select
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
      }}
      label="Salle"
    >
      {
        salle.map((v) => (
          <MenuItem value={v.id}>{v.name}</MenuItem>
        ))
      }
    </Select>
  )
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'client_first_name', headerName: 'Nom', width: 130, editable: true },
  { field: 'client_name', headerName: 'Prénom', width: 130, editable: true },
  {
    field: 'created_at',
    headerName: 'Date création',
    width: 90, type: 'dateTime'
  },
  { field: 'date_begin', headerName: 'Date début', width: 130, editable: true, type: 'dateTime' },
  { field: 'date_end', headerName: 'Date fin', width: 130, editable: true, type: 'dateTime' },
  {
    field: 'Salle',
    headerName: 'Salle',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    // editable: true,
    renderEditCell: (params) => (
      <CustomEditSalle {...params} />
    )
  },
  { field: 'validated', headerName: 'Validé', width: 130, editable: false, renderCell: (params) => {
    return (<CustomButton {...params} />)
  }},
  {
    field: 'reserved',
    headerName: 'Reservé',
    description: "L'alias",
    sortable: false,
    width: 50,
    // editable: true,
    renderEditCell: (params) => (
      <CustomEditSalle {...params} />
    )
  }
];

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

export const Historique = ({open}) => {
  const [reservation, setReservation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const fetchReservation = async () => {
    const {data, error} = await supabase
        .from('Reservation')
        .select(`id, created_at, client_name, client_first_name, email, date_begin, date_end, validated, Salle(id, name, alias, reserved), Commande(id)`)
      // .select(`id, Salle.name`)

    data.filter((column) => {
      column.salle_fk = column.Salle.id
      column.reserved = column.Salle.reserved ? 'OUI' : 'NON';
      column.Salle = column.Salle.name
      column.created_at = new Date(column.created_at)
      column.date_begin = new Date(column.date_begin)
      column.date_end = new Date(column.date_end)
      // console.log(column)
    })

    if (error) {
      setFetchError('Could not fetch the smoothies');
      setReservation([]);
      console.log(error)
    }

    if (data) {
      setReservation(data);
      setFetchError(null);
    }
    setLoading(false);
  }

  const updateReservation = async (newRow, oldRow) => {
    const {data, error} = await supabase
      .from('Reservation')
      // .select(`id, Salle.name`)
      .update({
        client_name: newRow.client_name,
        client_first_name: newRow.client_first_name,
        email: newRow.email,
        date_begin: newRow.date_begin,
        date_end: newRow.date_end,
        validated: newRow.validated,
        salle_fk: newRow.salle_fk
      })
      .eq('id', newRow.id)

      console.log(error)
      fetchReservation();

    // data.filter((column) => {
    //   column.salle_fk = column.Salle.id
    //   column.Salle = column.Salle.name
    //   column.created_at = new Date(column.created_at)
    //   column.date_begin = new Date(column.date_begin)
    //   column.date_end = new Date(column.date_end)
    //   // console.log(column)
    // })

    // if (error) {
    //   setFetchError('Could not fetch the smoothies');
    //   setReservation([]);
    //   console.log(error)
    // }

    // if (data) {
    //   setReservation(data);
    //   setFetchError(null);
    // }
  }

  useEffect(() => {
    fetchReservation();
  }, []);

  return (
      <Main open={open}>
        {/* {
          console.log(reservation)
        } */}
          <DrawerHeader />
          <Typography variant="h6">Rerservations non validées</Typography>
          <Box height={400} width='100%'>
            {console.log(reservation)}
            <DataGrid
              processRowUpdate={updateReservation}
              // onRowEditStop={(params, event) => {
              //   console.log(params)
              // }}
              onProcessRowUpdateError={(error) => console.log(error)}
              rows={reservation}
              columns={columns}
              pageSizeOptions={[50,100]}
              paginationMode="client"
              pageSize={5}
              autoPageSize={true}
              rowsPerPageOptions={[5]}
              // checkboxSelection
              editMode="row"
              loading={loading}
              slots={{
                noRowsOverlay: emptyCustom
              }}
            />
          </Box>
      </Main>
  )
}

export const emptyCustom = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}  
      >
        <img width={'40%'} src="https://res.cloudinary.com/mouss/image/upload/v1691957219/safina/undraw_Faq_re_31cw_czrbxw.png" alt="emptyImage" />
      </Box>
    </>
  )
}