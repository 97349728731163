import { createTheme, ThemeProvider } from '@mui/material'
import './App.css'
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { Dashboard } from './components/dashboard/Dashboard';
import { lightTheme } from './ThemeComponents';
import { LandingPage } from './components/landingPage/LandingPage';
import { HomePage } from './components/dashboard/content/HomePage';
import { ReservationPage } from './components/dashboard/content/ReservationPage';
import { SallesPages } from './components/dashboard/content/SallesPage';
import { HistoriquePage } from './components/dashboard/content/HistoriquePage';
import { ClientPage } from './components/dashboard/content/ClientPage';
import { useEffect, useState } from 'react';
import { Historique } from './components/dashboard/content/Historique';
import supabase from './config/SupabaseClient';
import { NotFound } from './components/Components';
import { SallePage } from './components/salleinfo/SallePage';
import { CulturePage } from './components/cultureinfo/CulturePage';
import { AnnoncePage } from './components/dashboard/content/AnnoncePage';
import { BibliothequePage } from './components/bibliotheque/BibliothequePage';


function App() {
  const [open, setOpen] = useState(false);
  const [session, setSession] = useState(null)

  const handleDrawerOpen = () => {
      setOpen(true);
  };

  const handleDrawerClose = () => {
      setOpen(false);
  };


  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

    if (!session) {
      console.log(session)
    }
    else {
      console.log(session)
    }

  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage session={session} />} />
            <Route path='salles' element={<SallePage session={session} />} />
            <Route path='cultures' element={<CulturePage session={session} />} />
            <Route path='bibliotheque' element={<BibliothequePage session={session} />} />
            {
              session ? (
                <Route path='dashboard' element={<Dashboard open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />} >
                  <Route index element={<HomePage open={open} />} />
                  <Route path='reservations' element={<ReservationPage open={open} />} />
                  <Route path='salles' element={<SallesPages open={open} />} />
                  <Route path='historique' element={<HistoriquePage open={open} />} />
                  <Route path='annonces' element={<AnnoncePage open={open} />} />
                  {/* <Route path='clients' element={<Historique open={open} />} /> */}
                </Route>
              ): (
                <Route path='*' element={<LandingPage session={session} />}/>
              )
            }
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

const Layout = () => {
  return <Outlet />
}

export default App;
