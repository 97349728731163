import { Box, styled, Typography } from '@mui/material'
import React from 'react'

export const SectionTypography = styled(Typography)(() => ({
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'primary.main'
}))

export const SectionStartTypography = styled(Typography)(() => ({
    fontWeight: 'bold',
    color: 'primary.main',
}))

export const Paragraphe = styled(Typography)(() => ({
    color: 'primary.main',
    marginBottom: '20px'
}))


export const NotFound = () => {
    return (
        <Box>

                <Typography>Rien à afficher !</Typography>
        </Box>
    )
}

export const permissionEmail = ['admin@maisonsafina.org']