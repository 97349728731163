import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CampaignIcon from '@mui/icons-material/Campaign';


import HomeIcon from '@mui/icons-material/Home';
import ChairIcon from '@mui/icons-material/Chair';
import ApartmentIcon from '@mui/icons-material/Apartment';
import UpdateIcon from '@mui/icons-material/Update';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Box, CircularProgress, Drawer, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import supabase from '../../config/SupabaseClient';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const DrawerSide = ({open, handleDrawerClose, theme}) => {
  const [selectedNavLink, setSelectedNavLink] = useState(0);

  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })
  }, []);

  const logOut = () => {
    supabase.auth.signOut();
  }

  return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: 3
        }}
        >
          {
            session ? (
                <>
                  <Avatar sx={{
                    width: 75,
                    height: 75
                  }} src={session.user.user_metadata.picture} alt='avatar' />
                  <Box>
                      <Typography variant='h6' textAlign='center'>{session.user.user_metadata.full_name}</Typography>
                      <Typography variant='subtitle2' textAlign='center'>{session.user.user_metadata.email}</Typography>
                  </Box>
                </>
              ): (
              <CircularProgress />
            )
          }
        </Box>
        <Divider />
        <List>
            <NavLink to='/dashboard'
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedNavLink === 0}
                  onClick={() => setSelectedNavLink(0)}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography comp>Acceuil</Typography>} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to='/dashboard/reservations'
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedNavLink === 1}
                  onClick={() => setSelectedNavLink(1)}
                >
                  <ListItemIcon>
                    <ChairIcon />
                  </ListItemIcon>
                  <ListItemText primary='Réservations' />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to='/dashboard/salles'
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedNavLink === 2}
                  onClick={() => setSelectedNavLink(2)}
                >
                  <ListItemIcon>
                    <ApartmentIcon />
                  </ListItemIcon>
                  <ListItemText primary='Salles' />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to='/dashboard/historique'
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedNavLink === 3}
                  onClick={() => setSelectedNavLink(3)}
                >
                  <ListItemIcon>
                    <UpdateIcon />
                  </ListItemIcon>
                  <ListItemText primary='Historique' />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to='/dashboard/annonces'
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedNavLink === 4}
                  onClick={() => setSelectedNavLink(4)}
                >
                  <ListItemIcon>
                    <CampaignIcon />
                  </ListItemIcon>
                  <ListItemText primary='Annonces' />
                </ListItemButton>
              </ListItem>
            </NavLink>

            <ListItem disablePadding>
              <ListItemButton
                selected={selectedNavLink === 5}
                onClick={() => setSelectedNavLink(5)}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText onClick={logOut} primary='Se déconnecter' />
              </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
  )
}